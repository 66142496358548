<template>
  <div>
    <div  class="length-text">
      <span>Всего {{ filteredList.length }} {{ getSpell(filteredList.length)}}</span>
    </div>
    <el-table
      ref="table"
      :data="filteredList"
      border
      fit
      highlight-current-row
      size="mini"
      class="main-table"
      @header-dragend="handleWidth"
      :row-class-name="tableRowClassName">
      <el-table-column type="expand" v-if="expandTable">
        <template slot-scope="props">
          <div style="margin: 10px">
            <el-table
              :data="props.row.inner"
              fit
              highlight-current-row
              size="mini"
              class="main-table">
              <el-table-column
                v-for="column in innerColumns"
                :key="column.field"
                :label="column.label"
                :align="column.align || 'center'"
                :prop="column.field"
                :width="column.width">
                <template slot-scope="scope">
                  <template>
                    {{ getValue(scope.row, column) }}
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in columns"
        sortable
        :key="column.field"
        :label="column.label"
        :align="column.align || 'center'"
        :prop="column.field"
        :width="column.width">
        <template slot-scope="scope">
          <template v-if="column.type === 'link'">
            <el-link type="primary" @click="goToLink(scope.row, column)">
              Просмотр
            </el-link>
          </template>
          <template v-else-if="column.type === 'file'">
            <el-link type="primary" @click="downloadFile(scope.row)">
              {{ getValue(scope.row, column) }}
            </el-link>
          </template>
          <katex-element v-else-if="column.type === 'formula' && scope.row[column.field].indexOf('`') >= 0" :expression="getFormula(scope.row[column.field])"/>
          <template v-else>
            {{ getValue(scope.row, column) }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-if="showPagination"
      v-show="list.length > 0"
      :limit.sync="per_page"
      :page.sync="current_page"
      :page-sizes="pageSizes"
      :total="list.length" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { downloadFile } from '@/api/file'
import { getFormatDate } from '@/utils'
import AsciiMathParser from 'asciimath2tex'

export default {
  components: {
    Pagination
  },
  props: {
    name: {
      type: String,
      required: true
    },
    colored: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    expandTable: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    innerColumns: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      per_page: 5,
      current_page: 1,
      pageSizes: [5, 10, 15, 20]
    }
  },
  computed: {
    filteredList () {
      if (this.showPagination) {
        let start = 0
        let end = this.per_page
        if (this.current_page !== 1) {
          start = (this.current_page - 1) * this.per_page
          end = this.per_page + start
        }
        return this.list.slice(start, end)
      }
      return this.list
    }
  },
  methods: {
    getFormula (val) {
      if (val.indexOf('`') >= 0) {
        val = val.replaceAll('`', '')
        const parser = new AsciiMathParser()
        return parser.parse(val).replaceAll('∑', 'Σ')
      }
      return val
    },
    getSpell (val) {
      if (val % 100 < 20 && val % 100 > 10) {
        return 'записей'
      }
      if (val % 10 === 1) {
        return 'запись'
      } else if (val % 10 < 5 && val % 10 > 1) {
        return 'записи'
      }
      return 'записей'
    },
    goToLink (row, column) {
      this.$router.push({ name: column.route, params: { id: row.id } })
    },
    tableRowClassName ({ row }) {
      if (this.colored) {
        if (row.completed === row.should_be_entered) {
          return 0
        }
        if (Math.round(((row.completed / row.should_be_entered) * 100)) >= 50) {
          return 'row-warning'
        } else {
          return 'row-danger'
        }
      } else {
        if (row.deleted_at) {
          return 'row-deleted'
        }
      }
      return 0
    },
    getValue (row, column) {
      if (column.type === 'date') {
        return getFormatDate(row[column.field])
      }
      if (column.type === 'percent') {
        if (row.should_be_entered !== 0) {
          return Math.round(((row.completed / row.should_be_entered) * 100))
        }
        return 0
      }
      if (column.type === 'equals_min') {
        return row[column.field] ? '≥' : '>'
      }
      if (column.type === 'equals_max') {
        return row[column.field] ? '≤' : '<'
      }
      if (column.type === 'stat') {
        return +row[column.field]
      }
      if (column.type === 'bound') {
        return row[column.field] ? 'Привязано' : 'Отвязано'
      }
      if (column.field.split('.').length > 1) {
        const val = column.field.split('.').reduce((a, b) => a?.[b], row)
        if (typeof val === 'boolean') {
          return val ? 'Да' : 'Нет'
        }
        return val
      }
      if ((typeof row[column.field]) === 'boolean') {
        return row[column.field] ? 'Да' : 'Нет'
      }
      return row[column.field]
    },
    handleWidth (newWidth, oldWidth, column) {
      // this.options.width[column.property] = newWidth
    },
    downloadFile (file) {
      downloadFile(file.id).then(({ data }) => {
        const url = URL.createObjectURL(new Blob([data], { type: data.type }))
        if (data.type === 'application/pdf') {
          window.open(url, '_blank')
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = url
          a.download = file.name
          a.target = '_blank'
          a.click()
        }
      })
    }
  }
}
</script>

<style scoped>

.main-table >>> .el-table__header-wrapper {
  /* need for sticky header */
  top: 50px;
  z-index: 1;
}

.el-table >>> .cell {
  line-height: 17px;
  font-size: 14px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}

.el-table >>> thead > tr:nth-child(2) > th > div.cell {
  line-height: 0;
  padding: 0 10px;
}

.el-table >>> .row-warning {
  background: #ffeeba;
}

.el-table >>> .row-danger {
  background: #f5c6cb;
}
</style>
